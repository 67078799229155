<template>
  <q-card-section>
    <q-dialog v-model="referalModal" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">{{ $t('login.referalTitle') }}</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div>
            <q-select
              clearable
              filled
              v-model="selectedGear"
              :options="filteredGears"
              :label="$t('common.gear')"
              class="q-mb-sm"
            />
            <q-input
              v-model.number="runnerNumber"
              type="number"
              :label="$t('login.runnerNumber')"
              filled
            />
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat :label="$t('common.close')" v-close-popup />
          <q-btn flat :label="$t('common.submit')" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-form>
      <q-input
        ref="email"
        square
        clearable
        v-model="email"
        type="email"
        lazy-rules
        :rules="[required, isEmail, short]"
        :label="$t('login.email')"
      >
        <template v-slot:prepend>
          <q-icon name="email" />
        </template>
      </q-input>
      <q-input
        ref="phoneNumber"
        square
        clearable
        v-model="phoneNumber"
        type="text"
        lazy-rules
        :rules="[isPhoneNumber]"
        :label="$t('login.phoneNumber')"
      >
        <template v-slot:prepend>
          <q-icon name="phone" />
        </template>
      </q-input>
      <q-input
        ref="username"
        square
        clearable
        v-model="username"
        lazy-rules
        :rules="[required, short]"
        type="username"
        :label="$t('login.username')"
      >
        <template v-slot:prepend>
          <q-icon name="person" />
        </template>
      </q-input>
      <q-input
        ref="password"
        square
        clearable
        v-model="password"
        :type="passwordFieldType"
        lazy-rules
        :rules="[required, short]"
        :label="$t('login.password')"
      >
        <template v-slot:prepend>
          <q-icon name="lock" />
        </template>
        <template v-slot:append>
          <q-icon
            :name="visibilityIcon"
            @click="switchVisibility"
            class="cursor-pointer"
          />
        </template>
      </q-input>
      <q-input
        ref="repassword"
        square
        clearable
        v-model="repassword"
        :type="passwordFieldType"
        lazy-rules
        :rules="[required, short, diffPassword]"
        :label="$t('login.repassword')"
      >
        <template v-slot:prepend>
          <q-icon name="lock" />
        </template>
        <template v-slot:append>
          <q-icon
            :name="visibilityIcon"
            @click="switchVisibility"
            class="cursor-pointer"
          />
        </template>
      </q-input>

      <q-select 
        ref="accountType"
        v-model="accountType"
        square
        clearable 
        :label="$t('login.accountType')" 
        :rules="[accountTypeValidation]"
        :options="this.accountTypes">
        <template v-slot:prepend>
          <q-icon name="person" />
        </template>
      </q-select>

      <div v-if="accountType?.value === 'photographer'">
        <p class="q-mt-sm q-mb-xs">{{ $t('login.portfolioTitle') }}</p>
        <q-input 
          ref="portfolio"
          square
          clearable
          v-model="portfolio" 
          type="url" 
          :label="$t('login.portfolio')"
          :rules="[isPortfolioUrl]"
        >
          <template v-slot:prepend>
            <q-icon name="link" />
          </template>
        </q-input>
      </div>

    </q-form>
    <q-card-actions class="q-px-lg">
      <div class="q-mt-sm q-mb-sm warning-info" v-if="accountType?.value === 'supporter'">
        <q-icon name="info" color="warning" size="md" />
        <span class="text-warning q-ml-xs">{{ $t('login.referalDesc') }}</span>
      </div>
      <q-btn
        v-if="accountType?.value === 'supporter'"
        unelevated
        size="lg"
        color="secondary"
        @click="showModal"
        class="full-width text-white q-mb-xs q-mb-sm"
        :label="$t('login.referal')"
      />
      <q-btn
        unelevated
        size="lg"
        color="primary"
        @click="submit"
        class="full-width text-white"
        :label="$t('login.register')"
      />
    </q-card-actions>
  </q-card-section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import _ from "lodash";
import { validationMethods as validate } from '@/services/validation.service';

export default {
  name: 'RegisterForm',
  data() {
    return {
      email: '',
      phoneNumber: '',
      username: '',
      password: '',
      repassword: '',
      accountTypes: [],
      accountType: null,
      portfolio: '',
      passwordFieldType: 'password',
      visibility: false,
      visibilityIcon: 'visibility',
      gears: [],
      selectedGear: '',
      referalModal: false,
      runnerNumber: 0,
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.global.lang,
      gearsList: (state) => state.global.gearsList,
    }),
    
    filteredGears() {
      return [...this.gears].map((el) => ({
          ...el,
          value: el.id,
          label: el.post_title
        }));
    },

    getAccountTypes() {
      return [
        {
          label: this.$t('login.supporter'),
          value: 'supporter',
        },
        {
          label: this.$t('login.photographer'),
          value: 'photographer',
        },
      ]
    },
    getAccountType() {
      return _.head(this.accountTypes);
    },
  },
  watch: {
    lang() {
      this.$refs.email.resetValidation();
      this.$refs.phoneNumber.resetValidation();
      this.$refs.password.resetValidation();
      this.$refs.repassword.resetValidation();
      this.$refs.username.resetValidation();
      this.$refs.accountType.resetValidation();
      this.$refs.portfolio?.resetValidation();
    },
    getAccountTypes(newAccountTypes) {
      this.accountTypes = newAccountTypes;
    },
    getAccountType(newAccountType) {
      this.accountType = newAccountType;
    },
  },
  methods: {
    ...mapActions({
      register: 'auth/register',
      login: 'auth/login',
      getGears: "global/getGears",
    }),
    showModal() {
      this.referalModal = true;
    },
    required(val) {
      return validate.required(val, this.$t);
    },
    diffPassword(val) {
      const val2 = this.password;
      return (val && val === val2) || this.$t('validation.samePassword');
    },
    short(val) {
      return validate.short(val, this.$t);
    },
    isEmail(val) {
           return validate.isEmail(val, this.$t);
    },
    isPhoneNumber(val) {
      return validate.isPhoneNumber(val, this.$t);
    },
    isPortfolioUrl(val) {
      return validate.isPortfolioUrl(val, this.$t);
    },
    accountTypeValidation(val) {
      return val && this.accountTypes.indexOf(val) !== -1  || this.$t('validation.accountType');
    },
    switchVisibility() {
      this.visibility = !this.visibility;
      this.passwordFieldType = this.visibility ? 'text' : 'password';
      this.visibilityIcon = this.visibility ? 'visibility_off' : 'visibility';
    },
    loginUser(email, password) {
      const vm = this;
      vm.login({
        email: email,
        password: password,
      }).then(
        () => {
          vm.$q.loading.hide();
          vm.$router.push('/');
        },
        (error) => {
          vm.$q.loading.hide();
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          vm.$q.notify({
            icon: 'fail',
            color: 'negative',
            message: message,
          });
        }
      );
    },
    submit() {
      const vm = this;
      vm.$refs.email.validate();
      vm.$refs.phoneNumber.validate();
      vm.$refs.username.validate();
      vm.$refs.password.validate();
      vm.$refs.repassword.validate();
      vm.$refs.accountType.validate();
      vm.$refs.portfolio?.validate();
      if (
        !vm.$refs.email.hasError &&
        !vm.$refs.phoneNumber.hasError &&
        !vm.$refs.password.hasError &&
        !vm.$refs.repassword.hasError &&
        !vm.$refs.username.hasError &&
        !vm.$refs.accountType.hasError &&
        !vm.$refs.portfolio?.hasError
      ) {
        vm.$q.loading.show();
        vm.register({
          username: vm.username,
          email: vm.email,
          phoneNumber: vm.phoneNumber.replace(/[ -/]/g, ''),
          password: vm.password,
          gear: vm.selectedGear?.id,
          runnerNumber: vm.runnerNumber,
          accountType: vm.accountType?.value,
          portfolio: vm.portfolio,
        }).then(
          ({message}) => {
            vm.$q.notify({
              icon: 'done',
              color: 'positive',
              message: this.$t(message),
            });
            vm.loginUser(vm.email, vm.password);
            vm.$q.loading.hide();
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            vm.$q.notify({
              icon: 'warning',
              color: 'negative',
              message: this.$t(message),
            });
            vm.$q.loading.hide();
          }
        );
      }
    },
  },
  created() {
    this.accountTypes = this.getAccountTypes;
    this.accountType = this.getAccountType;
    this.gears = this.gearsList;
  },
};
</script>
<style lang="scss" scoped>
.warning-info {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    text-align: start;
    font-weight: 600;
    line-height: 1.1;
  }
}
</style>
