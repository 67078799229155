export default {
 ERR_NETWORK: 'Sorry, there was a network problem, please try again later ..',
 ERR_BAD_RESPONSE:
  'Invalid server response. Sorry, please try again, if unsuccessful, please come back in some time.',
 login: {
  loginTitle: 'Login',
  emailInfo:
   'If you want to get commission for uploaded photos, please enter the email address associated with your paypal account',
  email: 'Email',
  phoneNumberInfo:
   'If you want to payout money, please enter correct phone number',
  phoneNumber: 'Phone number',
  password: 'Password',
  repassword: 'Repeat password',
  forgotPassword: 'Do not you remember the password?',
  loginButton: 'Sign in',
  loginNotify: 'Logged in!',
  register: 'Registration',
  username: 'User name',
  registration: 'Register',
  createAccount: 'Create an account',
  referal: 'Add referal',
  referalTitle: 'Add a referring runner',
  referalDesc:
   "(optional) You can enter the STARTING number of your friends runner - for every 50 pictures uploaded by the number's friends, they get a 5% discount!",
  runnerNumber: 'Runner number',
  accountType: 'Account type',
  supporter: 'Amateur photographer/bystander',
  photographer: 'Professional photographer (hired)',
  portfolioTitle:
   'I am a professional photographer, I want to receive payment for my photos. I am applying for verification - Link to my portfolio:',
  portfolio: 'Portfolio',
 },
 recoverPassword: {
  recoverDescription:
   'Please input an email used when registering account, we will send you a link to recover your password',
  recoverButton: 'Recover password',
  recoverGoBack: 'Hide password recovery',
  recoverSuccess:
   'If an account with this email exists, a recovery mail has been sent',
  recoverError: 'An error occured while sending a recovery mail',
  title: 'Set a new password',
  resetButton: 'Change password',
  repeat: 'Repeat password',
  resetInfo: 'Password strength requirements:',
  requirements1: 'min 8 characters',
  requirements2: 'min 1 uppercase',
  requirements3: 'min 1 lowercase',
  requirements4: 'min 1 number',
  resetSuccess: 'Password changed! You may now login.',
  invalidEmail: 'Incorrect email',
  invalidKey: 'Password reset key has expired, please request a new reset email.',
  validation: 'Password strength requirements not met.',
 },
 validation: {
  required: 'The field is required',
  minValue: 'The minimum number of characters is 3',
  email: 'Please enter a valid email',
  countryRequired: 'Select a country',
  phoneNumber: 'Please enter a valid phone number',
  samePassword: 'Passwords should be identical',
  completeAllFields: 'Complete all data',
 },
 duplicates: {
  general:
   'Your recent photos that you uploaded are duplicates of photos that already exist in the system. The following races contain duplicates:',
  unknownRace: 'Unknown run',
 },
 navigation: {
  home: 'Home',
  addPhoto: 'Photo',
  walletProfile: 'Wallet',
  myRaces: 'My races',
  logout: 'Sign out',
  profile: 'Your profile',
  signAs: 'Signed in as',
  signIn: 'Sign in',
  role: 'Role',
 },
 races: {
  description:
   "Races you are part of are displayed below, click on the button to go to individual race's dashboard",
  eventStart: 'Race date',
  albumsQty: 'Albums',
  button: 'Go to dashboard',
  loading: 'Loading your races...',
  noRacesMessage: 'You are not part of any races.',
  noOtherRaces: 'No other races',
 },
 dashboard: {
  menu: {
   shares: 'Shares',
   totalOrders: 'Orders',
   backBtn: 'Back to races',
   stats: 'Statistics',
   addPhoto: 'Add photos',
   prefillForm: 'Form has been prefilled with race data',
   showMore: 'Show more',
   showLess: 'Hide',
  },
  main: {
   salesReport: 'Sales report',
   earnedCommision: 'Earned commission',
   comissionPayout: 'Available to withdraw',
   withdraw: 'Withdraw',
   albumViews: 'Album views',
   albumPhotos: 'Photos in a race',
   photosSold: 'Purchased photos',
   startDate: 'Event date',
   totalOrders: 'Total orders',
   totalAlbumSalesAmount: 'Album sales',
   socialMediaSharesCount: 'Shares',
   totalCommissionEarned: 'Commission earned',
   totalCommissionPaid: 'Total withdrawn',
   amountToBePaid: 'Available to withdraw',
   totalAlbumViews: 'Total album views',
   totalPhotosInRace: 'Total photos in race',
   totalAlbumsSold: 'Albums sold',
   totalPhotosSold: 'Photos sold',
   loading: 'Loading race data...',
  },
  ordersTable: {
   title: 'Orders completed',
   sumCommissions: 'Total commissions',
   orderId: 'Order no.',
   orderDate: 'Transaction date',
   linkOrderItem: 'Album/image name',
   orderAmount: 'Base price',
   coupon: 'Coupon(%)',
   currency: 'Currency',
   authorCommission: 'Photo author commission',
   paygateCommission: 'Payment operator fees',
   runpixieCommission: 'Runpixie fees',
   afterFeesTotal: 'Earned total',
  },
  sharesTable: {
    title: 'Shares table',
    shareId: 'ID',
    link: 'Link',
    copy: 'Copy',
    linkCopySuccess: 'Link copied to clipboard!',
    linkCopyFail: 'Failed to copy link',
    noData: 'No shared albums on record'
  }
 },
 role: {
  photographer: 'Photographer',
  supporter: 'Supporter',
  organizer: 'Organizer',
  sponsor: 'Sponsor',
  photographer_verification: 'Photographer (verification)',
 },
 addPhoto: {
  title: 'Add photos',
  blockedText: 'To add photos log in',
  subHeaderTitle: 'RunPixie - Find Yourself!',
  agreement:
   'By uploading photos, you accept the Terms, conditions of use, returns and cancellations policy, licence agreement',
 },
 common: {
  clear: 'Clear',
  add: 'Add',
  subHeaderTitle: 'RunPixie - Find Yourself!',
  country: 'Country',
  completeData: 'Complete data',
  city: 'City',
  selectDate: 'Select date',
  close: 'Close',
  submit: 'Submit',
  selectedDate: 'Selected date:',
  gear: 'Race',
  summary: 'Summary',
  imagesCount: 'Selected images',
  date: 'Date',
  next: 'Next',
  return: 'Back',
  ups: 'Something went wrong!',
  turnOnThumbs: 'Enable thumbnails in the preview',
  turnOnThumbsInfo:
   'Enable this option if you want to see thumbnails in the preview (not recommended when adding more photos)',
  statuteRoute: '/en/license-agreement/',
  checkHere: 'check here',
  understand: 'I understand',
 },
 userPanel: {
  title: 'User panel',
  hello: 'Hello',
  addedPhoto: 'Added photos',
  inSystem: 'In system from',
  country: 'Country',
  city: 'City',
  gear: 'Race',
  emailChanges: 'Email was changed',
  badEmail: 'Please enter a valid email',
  walletRequestsTitle: 'Registered withdrawal requests',
  walletCommissionsTitle: 'Registered commissions',
  blockedMoneyInfo: 'Value of your current withdrawal requests (blocked)',
  noPersonalDataInfo:
   'To create withdrawal proposal, please fill your personal data: first name, last name, phone number and address',
  noWithdrawIntegration:
   'To create withdrawal proposal, Your account must be integrated with the payment partner',
  withdrawMoney: 'Dispose of funds',
  minWithdrawValue: 'Minimum Withdrawal Amount',
  withdraw: 'Withdraw',
  wallet: 'Wallet',
  ownCommisionsUnpaid: 'The sum of unpaid commissions',
  ownCommisionsAll: 'The sum of all commissions earned',
  albumName: 'Album name',
  commission: 'Commission',
  status: 'Status',
  sum: 'Sum',
  yourData: 'Your data',
  phoneNumber: 'Nr.',
  paymentInfo:
   'Data necessary to make withdrawals - if after the change, you can still see the old data, log out and log in again.',
  paid: 'Paid',
  tableWalletHint:
   'Below is a list of albums with your photos that someone has bought. Remember that the amount of the commission obtained is (a) the possible bonus for the best photo in the package and (b) the amount of the commission to be divided between the authors proportionally according to the number of photos.',
  tableColumns: {
   album: 'Number of the album sold',
   commission: 'The amount of commission received',
   gear: 'Race',
   date: 'Data',
   commissionData: 'Date of the commission payout',
   images: 'Images',
   image: 'Image',
   name: 'Name',
  },
  tableTitle: 'List of albums sold',
  imagesTableTitle: 'List of your photos within the album',
  promptWalletInfo:
   'You pay a commission for the license granted to RunPixie for photos that have been purchased by runners, in accordance with the website regulations and the license agreement',
  promptWalletInfo2:
   'Recommended payment above PLN 20, required after exceeding PLN 500. The cost of the payment is PLN 2.5. Funds not disbursed within 2 years are forfeited',
  regulamin: 'terms and conditions of the website and the license agreement',
  accountData: 'Account details:',
  billingAddress: 'Billing address',
  userData: {
   name: 'First name',
   surname: 'Last name',
   nrTelephone: 'Phone',
   adress: 'Address',
   address2: 'Address 2',
   state: 'State/Province',
   company: 'Company',
   country: 'Country',
   city: 'City',
   postcode: 'Postcode',
   email: 'Email',
   role: 'Role',
   submit: 'Update data',
  },
  empty: 'Not completed',
  completeData:
   'Complete data (you will be redirected to a new tab [you will need to log in again])',
  editData: 'Edit data',
  solded: 'Solded',
  withdrawNotConnectedInfo: 'No connection with the external withdraw platform',
  connectHereLabel: 'Connect here',
  billingDataUpdating: 'Updating...',
 },
 status: {
  waiting: 'Waiting',
  rejected: 'Rejected',
  completed: 'Completed',
 },
 error: {
  badAuth: 'Bad authorization.',
  badAuthToken: 'Bad authorization - theres no token.',
  badAuthPriviliges: 'Bad authorization - you need higher privileges.',
  badAuthTokenProblem: 'Bad authorization - problem with token.',
  route: 'You are not allowed to use this route.',
  walletNoAmmount: 'No amount or author or ballance fields',
  code: 'Something went wrong with code.',
  imageFail: 'Houston, we have got problem with upload image function',
  usernameRequired: 'Username field is required.',
  usernameExists: 'Username already exists.',
  emailRequired: 'Email field is required.',
  passwordRequired: 'Password field is required.',
  invalidCredentials: 'Invalid username or password.',
  emailExists: 'Email already exists, please try Reset Password.',
  phoneNumber: 'Phone number field is required.',
  phoneNumberExists: 'Phone number already exists.',
  addedImagesInfo: 'added images:',
  renewSendImages: 'Resume sending photos',
  sendLater: 'Close, i will send later',
  sendLaterInfo:
   'The photos from the first step have been updated, only those that have not been uploaded are left',
  catchedError: 'Error occured',
  registerWithdrawError: 'Withdraw registration error occured',
  errorBillingForm: 'Error while updating data, please check all fields.',
  fetchRaces:
   'Something went wrong while fetching races. Please try again later.',
  noDashboardData: 'Not enough data to populate. Please try again later.',
  invalidRace: 'This race does not exist or you do not have access to it.'
 },
 success: {
  registeredWithReq: 'A withdrawal request has been registered, thank you.',
  imageAdded: 'All, good. Images added, thank you!',
  accountHasBeenCreated: 'Account has been created successfully!',
  accountHasBeenCreatedPhotographerVerify:
   'Account has been created successfully! Type of your account is verifying.',
  billingDataUpdated: 'Billing data updated successfully!',
 },
};
