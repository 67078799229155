<template>
  <q-card-section>
    <q-form>
      <!-- Login -->
      <q-input
        ref="email"
        square
        clearable
        v-model="email"
        type="email"
        lazy-rules
        :rules="[required, isEmail, short]"
        :label="$t('login.email')"
      >
        <template v-slot:prepend>
          <q-icon name="email" />
        </template>
      </q-input>
      <q-input
        ref="password"
        square
        clearable
        v-model="password"
        :type="passwordFieldType"
        lazy-rules
        :rules="[required, short]"
        :label="$t('login.password')"
      >
        <template v-slot:prepend>
          <q-icon name="lock" />
        </template>
        <template v-slot:append>
          <q-icon
            :name="visibilityIcon"
            @click="switchVisibility"
            class="cursor-pointer"
          />
        </template>
      </q-input>
    </q-form>
    <q-card-actions class="q-px-lg">
      <q-btn
        unelevated
        size="lg"
        color="secondary"
        @click="submit"
        class="full-width text-white"
        :label="$t('login.loginButton')"
      />
      <q-btn
        unelevated
        size="lg"
        color="primary"
        @click="createAccount"
        class="full-width text-white q-mt-sm"
        :label="$t('login.createAccount')"
      />
    </q-card-actions>
    <!-- Password recovery -->
    <q-separator class="q-my-md" />
    <q-card-section v-if="isPasswordReset" class="q-pa-none q-pt-md">
      <div>
        <p class="q-px-lg text-balance">
          {{ $t("recoverPassword.recoverDescription") }}
        </p>
      </div>
      <q-input
        ref="resetEmail"
        square
        clearable
        v-model="resetEmail"
        type="email"
        lazy-rules
        :rules="[required, isEmail]"
        :label="$t('login.email')"
        class="q-mt-md"
      >
        <template v-slot:prepend>
          <q-icon name="email" />
        </template>
      </q-input>
      <q-btn
        unelevated
        size="lg"
        color="primary"
        @click="submitResetEmail"
        class="full-width text-white q-mt-sm"
        :label="$t('recoverPassword.recoverButton')"
      />
    </q-card-section>

    <q-card-section class="text-center q-pa-sm">
      <p
        class="text-grey-6 q-mt-sm cursor-pointer"
        @click="togglePasswordReset"
      >
        {{
          isPasswordReset
            ? $t("recoverPassword.recoverGoBack")
            : $t("login.forgotPassword")
        }}
      </p>
    </q-card-section>
  </q-card-section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AuthService from "@/services/auth.service";

export default {
  name: "LoginForm",
  data() {
    return {
      email: "",
      password: "",
      passwordFieldType: "password",
      visibility: false,
      visibilityIcon: "visibility",
      backendRoot: process.env.VUE_APP_BACK_ROOT,
      isPasswordReset: false,
      resetEmail: "",
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.global.lang,
    }),
  },
  watch: {
    lang() {
      this.$refs.email.resetValidation();
      this.$refs.password.resetValidation();
    },
  },
  methods: {
    ...mapActions("auth", ["login"]),
    createAccount() {
      this.$emit("showCreateAccount");
    },
    submit() {
      this.$refs.email.validate();
      this.$refs.password.validate();
      if (!this.$refs.email.hasError && !this.$refs.password.hasError) {
        this.$q.loading.show();
        this.login({
          email: this.email,
          password: this.password,
        }).then(
          async () => {
            this.$q.notify({
              icon: "done",
              color: "positive",
              message: this.$t("login.loginNotify"),
            });

            this.$q.loading.hide();
            this.$router.push("/");
          },
          (error) => {
            this.$q.loading.hide();
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.$q.notify({
              icon: "error",
              color: "negative",
              message: this.$t(message),
            });
          }
        );
      }
    },
    required(val) {
      return (val && val.length > 0) || this.$t("validation.required");
    },
    short(val) {
      return (val && val.length > 3) || this.$t("validation.minValue");
    },
    isEmail(val) {
      const emailPattern =
        /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(val) || this.$t("validation.email");
    },
    switchVisibility() {
      this.visibility = !this.visibility;
      this.passwordFieldType = this.visibility ? "text" : "password";
      this.visibilityIcon = this.visibility ? "visibility_off" : "visibility";
    },
    togglePasswordReset() {
      this.isPasswordReset = !this.isPasswordReset;
      if (!this.isPasswordReset) {
        this.$refs.email.focus();
      } else {
        this.resetEmail = this.email;
        this.$nextTick(() => {
          this.$refs.resetEmail.focus();
        });
      }
    },
    async submitResetEmail() {
      this.$refs.resetEmail.validate();

      if (this.$refs.resetEmail.hasError) {
        return;
      }

      this.$q.loading.show();
      try {
        await AuthService.sendResetLink(this.resetEmail); 
        this.$q.loading.hide();

        this.$q.notify({
          icon: "info",
          color: "info",
          message: this.$t("recoverPassword.recoverSuccess"),
        });

        this.togglePasswordReset();
      } catch (error) {
        this.$q.loading.hide();

        this.$q.notify({
          icon: "error",
          color: "negative",
          message: this.$t("recoverPassword.recoverError"),
        });
      }
    },
  },
};
</script>

<style scoped>
.text-balance {
  text-align: center;
  text-wrap: balance;
}
</style>