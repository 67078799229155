import Quasar from 'quasar/src/vue-plugin.js';
import langPl from 'quasar/lang/pl.js'
import langEn from 'quasar/lang/en-GB.js'


export default (lang) => {
    const DEFAULT_LANG = 'en';
    const availableLanguages = {
        'pl': langPl,
        'en': langEn, 
    }

    let languagetoSet = availableLanguages[lang];

    if (!languagetoSet) {
        languagetoSet =  availableLanguages[DEFAULT_LANG];
    }

    Quasar.lang.set(languagetoSet)
}
