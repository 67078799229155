import { createApp } from "vue";
import App from "./App.vue";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import i18n from "./lang";
import store from "./store";
import './registerServiceWorker'

createApp(App)
  .use(i18n)
  .use(Quasar, quasarUserOptions)
  .use(router)
  .use(VueAxios, axios)
  .use(store)
  .mount("#app");
  
  store.dispatch('global/getPageLang', i18n)
