export default function authHeaderQueue() {
  let token = JSON.parse(localStorage.getItem("larToken"));
  if (token) {
    return {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    };
  } else {
    return {};
  }
}
