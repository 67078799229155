<template>
  <q-card :style="`width: 700px; max-width: ${!isMobile ? '80vw' : '100vw'}`">
    <q-card-section class="row items-center q-pb-none">
      <div class="text-h6">{{ $t("userPanel.imagesTableTitle") }}</div>
      <q-space />
      <q-btn icon="close" flat round dense v-close-popup />
    </q-card-section>

    <q-card-section>
      <q-table :rows="images" :columns="columns" row-key="name">
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="image" :props="props">
              <q-img
                :src="props.row.author_image"
                class="viewer-image rounded-borders table-image"
                spinner-color="primary"
                fit="contain"
              />
            </q-td>
            <q-td key="title" :props="props">
              {{ `${props.row.post_title}` }}
            </q-td>
            <q-td key="gear" :props="props">
              {{ gear }}
            </q-td>
            <q-td key="date" :props="props">
              {{ props.row.post_date }}
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  name: "ImageModal",
  props: {
    images: {
      required: false,
      default: () => [],
      type: Array,
    },
    gear: {
      required: false,
      default: "",
      type: String,
    },
  },
  data() {
    return {
      columns: [
        {
          name: "image",
          label: this.$t("userPanel.tableColumns.image"),
          field: "author_image",
          align: "left",
          sortable: false,
          classes: "bg-grey-2 ellipsis",
          headerClasses: "bg-primary text-white",
        },
        {
          name: "title",
          align: "left",
          label: this.$t("userPanel.tableColumns.name"),
          field: "post_title",
          sortable: false,
        },
        {
          name: "gear",
          label: this.$t("userPanel.tableColumns.gear"),
          field: "name",
          align: "left",
          sortable: false,
        },
        {
          name: "date",
          label: this.$t("userPanel.tableColumns.date"),
          align: "left",
          field: "post_date",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$q.screen.width < 920;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-image {
  width: 80px;
}
</style>
