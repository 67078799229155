<template>
  <q-dialog v-model="isDialog" persistent position="bottom">
    <q-card style="width: 350px">
      <q-linear-progress :value="progress" color="pink" />

      <q-card-section class="row items-center no-wrap">
        <q-linear-progress
          dark
          stripe
          rounded
          size="20px"
          :value="progress"
          color="red"
          class="q-mt-sm"
        >
          <div class="absolute-full flex flex-center">
            <q-badge color="white" text-color="accent" :label="progressLabel" />
          </div>
        </q-linear-progress>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: "ProgressModal",
  props: {
    isProgress: {
      required: false,
      type: Boolean,
      default: false,
    },
    progress: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      isDialog: false,
    };
  },
  computed: {
    progressLabel() {
      return (this.progress * 100).toFixed(2) + "%";
    },
  },
  watch: {
    isProgress(val) {
      this.isDialog = val;
    },
  },
};
</script>
