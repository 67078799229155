import { createI18n } from "vue-i18n";
import pl from "./pl";
import en from "./en";

const locales = {
  pl: pl,
  en: en,
};

const getUserBrowserLang = () => {
  const navigatorLang = navigator.language || navigator.userLanguage;
  const lang = navigatorLang.split("-")[0];
  
  if (locales[lang]) {
    return lang;
  }

  return "en";
}

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: getUserBrowserLang(),
  messages: locales,
});

export default i18n;
