<template>

  <div v-if="!isLoading" class="justify-center">
    <q-pagination
      class="justify-center q-mt-lg"
      v-model="page"
      :max="photosPages"
      :max-pages="6"
      boundary-links
      v-if="photosPages > 1"
    />
  </div>

  <template v-if="isLoading">
    <div class="text-center">
      <img src="../../../assets/loader-icon.gif" alt="" />
    </div>
  </template>
  
  <div class="img-table__wrapper" v-if="!isLoading">
    <ImageViewerModal v-model="showViewer" :image="activeImage" />
    <p class="q-mt-md" v-if="addedPhotos && addedPhotos.length == 0">
      Nie dodałeś jeszcze żadnego zdjęcia.
      <router-link
        to="/"
        class="q-btn q-btn-item non-selectable no-outline q-btn--standard q-btn--rectangle bg-primary text-white q-btn--actionable q-focusable q-hoverable q-btn--dense q-mx-md q-px-lg"
      >
        dodaj teraz
      </router-link>
    </p>

    <q-card
      v-else
      v-for="(img, index) in addedPhotos"
      :key="index"
      class="my-card q-my-sm q-mx-xs image-card"
      @click="showImage(img)"
    >
      <q-img :src="`${img.url_image_thumb}`" fit="cover" style="max-height: 182px" />

      <q-card-section>
        <div class="row no-wrap items-center">
          <div class="col text-h6 ellipsis">{{ img.url_image.split('/').pop() }}</div>
        </div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <div class="text-subtitle1">
          {{ $t("userPanel.gear") }}: {{ img.wp_run_id }}
        </div>
        <div class="text-caption text-grey">
          {{
            `${$t("userPanel.city")} : ${img.wp_city_id}. ${$t(
              "userPanel.country"
            )}: ${img.wp_country_id}`
          }}
        </div>
        <q-badge v-if="isSolded(img.id)" color="green">
          {{ $t("userPanel.solded") }}
          <q-icon name="sell" color="white" class="q-ml-xs" />
        </q-badge>
      </q-card-section>

      <q-separator />

      <q-card-actions>
        <q-btn flat round icon="event" />
        <span>{{ formatDate(img.created_at) }}</span>
      </q-card-actions>
    </q-card>
  </div>
  <div v-if="!isLoading" class="justify-center">
    <q-pagination
      class="justify-center q-mt-lg"
      v-model="page"
      :max="photosPages"
      :max-pages="6"
      boundary-links
      v-if="photosPages >= 1"
    />
  </div>
</template>

<script>
import moment from 'moment';
import ImageViewerModal from "./ImageViewerModal.vue";
import { mapState } from "vuex";
import _ from "lodash";
export default {
  name: "ImagesTable",
  components: {
    ImageViewerModal,
  },
  data() {
    return {
      showViewer: false,
      activeImage: "",
      page: 1,
    };
  },
  computed: {
    ...mapState({
      addedPhotos: (state) => state.global.addedPhotos,
      photosPages: (state) => state.global.photosPages,
      soldedImages: (state) => state.global.soldedImages,
      isLoading: (state) => state.global.isLoading,
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    formatDate(date) {
      return moment(date).format("YYYY-DD-MM HH:mm");
    },
    showImage(image) {
      this.activeImage = image;
      this.showViewer = true;
    },
    isSolded(id) {
      let solded = _.find(this.soldedImages, { id });
      return solded;
    },
    fetchImages() {
      this.$store.dispatch("global/getAddedPhotos", { 
        userId: this.user.id, 
        page: this.page 
      });
    },
  },
  watch: {
    page(newVal) {
      this.fetchImages(newVal);
    },
  },
  mounted() {
    this.fetchImages(this.page);
  },
};
</script>



<style lang="scss" scoped>
.img-table {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
::v-deep {
  .q-img__image {
    transition: all 0.5s ease-in-out;
  }
}
.image-card {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  margin: 1rem 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;

  & > div:first-child {
    position: relative;
    flex-grow: 1;
  }
  &:hover {
    ::v-deep {
      .q-img__image {
        transform: scale(1.1);
      }
    }
    box-shadow: 0px 0px 43px -7px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 43px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 43px -7px rgba(0, 0, 0, 0.75);
  }
}
@media (max-width: $breakpoint-xs-max) {
  .img-table__wrapper {
    width: 100%;
    .image-card {
    width: 45%;
    flex-grow: 1;
    margin: 0.5rem 0.25rem;
    }
  }
  .q-card__section--vert {
    padding: 2px 8px;
  }
}
</style>
