import { createWebHistory, createRouter } from "vue-router";
import LoginPage from "@/pages/login/LoginPage.vue";
import PasswordResetPage from "@/pages/passwordReset/PasswordResetPage.vue";
import AddPhotoPage from "@/pages/addPhoto/AddPhotoPage.vue";
import UserPanelPage from "@/pages/userPanel/UserPanelPage.vue";
// import DashboardRacesListPage from '@/pages/dashboardRaces/dashboardRacesList/DashboardRacesListPage.vue';
// import DashboardRacesSinglePage from '@/pages/dashboardRaces/dashboardRacesSingle/DashboardRacesSinglePage.vue';
import routerGuardService from '@/services/routerGuard.service';

const routes = [
  {
    path: '/',
    name: 'addPhoto',
    component: AddPhotoPage,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/reset-password',
    name: 'PasswordReset',
    component: PasswordResetPage,
    beforeEnter: routerGuardService.validatePasswordReset
  },
  {
    path: '/userPanel',
    name: 'userPanel',
    component: UserPanelPage,
  },
  // {
  //   path: '/my-races',
  //   name: 'dashboardRacesList',
  //   component: DashboardRacesListPage,
  // },
  // {
  //  path: '/my-races/:raceId',
  //  name: 'dashboardRacesSingle',
  //  component: DashboardRacesSinglePage,
  //  beforeEnter: routerGuardService.validateDashboardRacesSingle
  // },
  {
   path: '/:pathMatch(.*)*',
   name: 'notFound',
   component: AddPhotoPage
  }
];


const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/reset-password', '/'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    return next('/login');
  }

  if (loggedIn) {
    const userData = JSON.parse(loggedIn);
    const allowedRoles = ['administrator', 'organizer', 'sponsor'];


    if (
     (to.name === 'dashboardRacesList' || to.name === 'dashboardRacesSingle') &&
     !allowedRoles.includes(userData.role)
    ) {
     return next('/');
    }
  }

  next();
});
export default router;
